export const photos = [
    {
      src: "https://i.imgur.com/13uSc8f.jpg",
      width: 4,
      height: 3
    },
    {
      src: "https://i.imgur.com/6RzHTvi.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/QFownDm.jpg",
      width: 3,
      height: 4
    },
    {
      src: "https://i.imgur.com/hG6hq4z.jpg",
      width: 3,
      height: 4
    },
    {
      src: "https://i.imgur.com/svxlMWg.jpg",
      width: 3,
      height: 4
    },
    {
      src: "https://i.imgur.com/r749bV4.jpg",
      width: 4,
      height: 3
    },
    {
      src: "https://i.imgur.com/lWDF7oG.jpg",
      width: 3,
      height: 4
    },
    {
      src: "https://i.imgur.com/WPntde8.jpg",
      width: 4,
      height: 3
    },
    {
      src: "https://i.imgur.com/INMDCwf.jpg",
      width: 4,
      height: 3
    },
    {
      src: "https://i.imgur.com/dEL8oa7.jpg",
      width: 4,
      height: 3
    },
    {
      src: "https://i.imgur.com/SvHf8nN.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/6HEbHwn.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/XrqUrQZ.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/2rnvXXc.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/ZsySx2Y.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/CHZjgg8.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/nauxFnj.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/uYyZ6rl.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/aQDOfnp.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/4iFEIyr.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/6doUixu.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/AArPHDS.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/BtmTNJf.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/g6egmgw.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/WiNw5FK.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/RZj3pPq.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/5OwTLNb.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/dDjSmuP.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/m1d4nhY.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/zHwpe8I.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/px5GGOs.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/8uXiCbl.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/SFQXMhr.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/HFTOZhS.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/SHYRFWt.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/fV3e06k.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/rVrrvmO.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/rcWx4lP.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/l8aILBZ.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/Hq2jaDp.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/i60Zkzz.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/Xh0EbBe.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/fbpJ1c8.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/Rwps2NK.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/hnDz4Qq.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/x8KieS4.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/3V20Oen.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/SdKvGFx.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/VR1x0uK.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/kvCo6tc.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/T7BPSXB.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/FblsyZ2.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/70JNzta.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/8tq69Ly.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/HeNmxLp.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/VpghtJv.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/q7pt9Gb.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/jEiZFC3.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/7e657oC.jpg",
      width: 1,
      height: 1
    },
    {
      src: "https://i.imgur.com/A15iq5d.jpg",
      width: 1,
      height: 1
    },
  ];