import React, { useState } from 'react'
import Layout from '../UI/Layout'
import Background from '../UI/Background'
import styled from '@emotion/styled'
import Wizard from '../modules/RSVP'
// import NavBar from '../components/NavBar'

// const Wrapper = styled.div`${({dark}) => `background-size: cover; background: ${dark ? 'url(/images/background.jpg)' : 'white'}; background-color: ${dark ? 'black' : 'white'}; color: ${dark ? "white" : "#323d47" }; transition: background-color 0.3s ease-out, color 0.3s ease-out; `}`))

const Wrapper = styled.section`
    width: 100%;
    

`
const Home = ({show}) => {

    return (
        <>
        <Layout/>
            {/* <Background /> */}
        <div style={{backgroundBlendMode: 'saturation'}}>
            <video style={{boxShadow: '1px 2px 3px rgba(0,0,0,.5)'}}  autoPlay muted loop id="myVideo">
                <source src="/images/Copy of Birthday.mp4" type="video/mp4"/>
            </video>
        </div>
        </>
        
    );
}

export default Home;