import React from 'react';
import styled from '@emotion/styled'
// import NavBar from '../components/NavBar';
import Layout from '../UI/Layout'
// import LocationPickerApp from '../components/LocationPicker'
// import Flex from '../components/Flex'
// import SeeMore from '../modules/SeeMore'
import PhotoSlide from '../UI/Carousel';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faChevronDown, faArrowDown } from '@fortawesome/free-solid-svg-icons'


const Venue = () => {

    const Heading = styled.h1`
    font-family: Goth;
    font-size: 4rem;
    text-align: center;
    `
    
    return ( 
        <Layout>
            <div style={{margin: '50px'}}></div>
            <PhotoSlide/>
            {/* <div className="arrow bounce">
                <FontAwesomeIcon className="fa fa-arrow-down fa-2x" icon={faArrowDown} href="#"></FontAwesomeIcon>
                <p>See more details</p>
            </div> */}
            {/* <section style={{background: 'url(/images/guestlistBackground.jpg)'}}>
            <Flex>
            <div className='card card-4'>
                <Heading>About</Heading>

            </div>
            <div className='card card-4'>
                <Heading>Getting there</Heading>
                NEAREST TUBE:

                <Copy>Piccadilly Circus Tottenham Court Road Leicester Square</Copy>

               <Copy> NEAREST PARKING
                Brewer St - https://www.ncp.co.uk/find-a-car-park/car-parks/london-brewer-street/Poland Street - https://www.q-park.co.uk/en-gb/cities/london/soho/Chinatown - https://www.q-park.co.uk/en-gb/cities/london/chinatown/?utm_source=google&utm_medium=organic&utm_campaign=LPM_google_Chinatown_</Copy>
            </div>
            </Flex>
            </section> */}
        </Layout>
    
     );
}
 
export default Venue;