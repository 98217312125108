import React from 'react'
import styled from '@emotion/styled'
import Flex from '../components/Flex'
import Layout from '../UI/Layout'

const TheLady = () => {

    const Heading = styled.h1`
        font-family: Goth;
        font-size: 2rem;
        text-align: center;
    `
    const Poem = styled.p`
        font-family: rom;
        line-height: 40px;
        padding: 1rem;
        overflow: auto;
    `
//     const Background = styled.section`
//     background: url(/images/birthdaygirlBack.jpg);
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
//     height: 100vh;
//   `
    return (
        <Layout>
       
            <iframe style={{margin: '0 auto', marginTop: '10px', display: 'flex'}} className='video' title='sarahvideo' width="1300px" height="700px" src="https://www.youtube.com/embed/rL-zFEo_pWo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
         
            
            <div style={{height: '100%', width: '60%', margin: '0 auto', display: 'flex', marginTop: '30px', marginBottom: '30px'}} className='card card-4' >
                <Heading>A poem by Mum</Heading>
                <Poem>When it's our birthday we all like a fuss, so when somebody special grows older with us, we remember each year as another one starts, now Sarah is 30 we look to our hearts at at a sweet-natured beauty, determined and strong, sassy and bossy who cannot be wrong, There's never a favour too large or too  small, her compassion is selfless, she looks after us all. has a particular tidy obsession and has chosen a busy and frantic profession, but who handles it well  with the hair and the nails the gym and the diet versus high pressured sales. And if you know Sarah, the way that we do, on the 6th, on her birthday, you'd celebrate too</Poem>
             </div>
        
        </Layout>

    );
}

export default TheLady;