import React from 'react'
import Carousel from 'react-bootstrap/Carousel'


const PhotoSlide = () => {

    return (

        <Carousel className='photos' style={{marginTop: '20px', width: '100vw', fontFamily: 'Goth'}}>
            <Carousel.Item>
                <a href='https://www.thegrouchoclub.com/' target='_blank' rel='noopener noreferrer' alt='groucho'>
                <img
                    className="d-block w-100"
                    src="images/FrontHouse.png"
                    alt="First slide"
                    // style={{width: '95vw'}}
                />
                </a>
                <Carousel.Caption>
                <h3>The Outside</h3>
                {/* {Arrow} */}
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
            <a href='https://www.thegrouchoclub.com/club/about-the-club/' target='_blank' rel='noopener noreferrer' alt='groucho'>
                <img
                    className="d-block w-100"
                    src="images/CLUB_banner.png"
                    alt="Second slide"
                    // style={{width: '95vw'}}
                />
                </a>

                <Carousel.Caption>
                    <h3>The Inside</h3>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
            <a href='https://www.thegrouchoclub.com/' target='_blank' rel='noopener noreferrer' alt='groucho'>
                <img
                    className="d-block w-100"
                    src="images/dinner.jpg"
                    alt="Third slide"
                    // style={{width: '95vw'}}
                />
                </a>

                <Carousel.Caption>
                    <h3>The Rooms</h3>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
            <a href='https://www.thegrouchoclub.com/menus/' target='_blank' rel='noopener noreferrer' alt='groucho'>
                <img
                    className="d-block w-100"
                    src="images/groucho3.png"
                    alt="Third slide"
                    // style={{width: '95vw'}}
                />
                </a>

                <Carousel.Caption>
                    <h3>The Food</h3>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
            <a href='https://www.thegrouchoclub.com/' target='_blank' rel='noopener noreferrer' alt='groucho'>
                <img
                    className="d-block w-100"
                    src="images/gourchoroom.png"
                    alt="Third slide"
                    // style={{width: '95vw'}}
                />
                </a>

                <Carousel.Caption>
                    <h3>The Club Room</h3>
                </Carousel.Caption>
            </Carousel.Item>
            
        </Carousel>
    );
}

export default PhotoSlide;