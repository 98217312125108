import React, { useState } from 'react'
import axios from '../axiosOrder'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import TextField from '@material-ui/core/TextField';
import Flex from '../components/Flex'

export const RsvpModal = ({ show, setShow }) => {

  const [openPlusOn, setPlusOne] = useState(false)
  const [guestlistName, setGuestlistName] = useState({
    name: '',
    surname: '',
    email: ''
  })

  const handleSubmit = () => {
    axios.post('/Guestlist.json', guestlistName)
      .then(response => {
        return response.data
      }).catch(e => {
        console.log(e)
        return false
      });
  }
  // const getNameObj =/

  return (
    <>
      <Modal show={show} onHide={() => setShow(!show)} animation={false} closeButton>
        <Flex justifyCenter alignCenter margin='0 0 20px 0'>
          <Modal.Header style={{ fontFamily: 'Bold', fontSize: '3rem', fontWeight: '900', marginBottom: '30px' }}>
            RSVP
          </Modal.Header>
          <h6 className='rsvp-heading'>For any enquires please contact Samuel Jay on 07908 841858</h6>
        </Flex>
        <Flex justifyCenter alignCenter column>

          <TextField inputProps={{style: {fontFamily: 'Goth'}}} id="standard-basic" label="First name" onChange={(e) => setGuestlistName({ name: e.target.value })} style={{ width: '70%', margin: '15px' }} />
          <TextField inputProps={{style: {fontFamily: 'Goth'}}} id="standard-basic" label="Second name" onChange={(e) => setGuestlistName({ ...guestlistName, surname: e.target.value })} style={{ width: '70%', margin: '15px' }} />
          <TextField inputProps={{style: {fontFamily: 'Goth'}}} id="standard-basic" label="Email" type='email' onChange={(e) => setGuestlistName({ ...guestlistName, email: e.target.value })} style={{ width: '70%', margin: '15px' }} />

        </Flex>
        <Flex justifyCenter style={{ margin: '40px 0 40px 0' }}>
          <Button
          style={{width: '200px', fontFamily: 'Goth'}}
            variant="primary"
            type='submit'
            onClick={() => {
              console.log(guestlistName)
              handleSubmit()
              setShow(!show)
            }
            }>
            Submit
            </Button>
          {/* <Button variant="secondary" onClick={() => setPlusOne(!openPlusOn)}>
            Add my plus one
            </Button> */}

        </Flex>
      </Modal>
    </>
  );

}

export default RsvpModal;