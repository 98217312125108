
import React from 'react'
import Home from './pages/Home'
import Venue from './pages/Venue'
import TheLady from './pages/TheLady'
import TheGuestlist from './pages/TheGuestlist'
import Gallery from './pages/Gallery'
import ThePlan from './pages/ThePlan'


export const routes = {
    '/': () => <Home />,
    '/thevenue': () => <Venue />,
    '/theplan': () => <ThePlan/>,
    '/thebirthdaygirl': () => <TheLady />,
    '/theguestlist': () => <TheGuestlist />,
    '/gallery': () => <Gallery />,
    // '/products/:id': ({id}) => <ProductDetails id={id} />
};
    
export default routes
// const MyApp = () => {
  
// }