import React, { useState } from 'react'
import styled from '@emotion/styled'
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import Nav from 'react-bootstrap/Nav'
import RSVPWizard from '../modules/RSVPWizard'
// // import Dropdown from 'react-bootstrap/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

const NavigationBar = ({show, setShow}) => {

    const Holder = styled.nav`
        width: 100%;
        height: 50px;
        background-color: rgba(45, 13, 42, 1) !important;
        z-index: 2;
    `

    return (
        <Holder>
            <Navbar className='nav-heading' style={{zIndex: '100'}} collapseOnSelect expand="lg"  variant='dark'>
                <Navbar.Brand href="/"><FontAwesomeIcon icon={faHome} color='#fff'></FontAwesomeIcon></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse style={{color: '#fff'}}id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="/theplan">The Plan</Nav.Link>
                        <Nav.Link href="/thevenue">The Venue</Nav.Link>
                        <Nav.Link href="/thebirthdaygirl">The Birthday Girl</Nav.Link>
                        <Nav.Link href="/theguestlist">The Guestlist</Nav.Link>
                        <Nav.Link href="/gallery">Gallery</Nav.Link>
                        {/* <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                        </NavDropdown> */}
                    </Nav>
                    {/* <img src='/images/SMlogo.png' alt='logo'/> */}
                    <Nav>
                    
                            <Button variant="primary" show={show} onClick={() => setShow(!show)}>
                                RSVP NOW
                            </Button>
            
                       
                        <Nav.Link style={{color: '#fff'}}eventKey={2} href="mailto:mrsamueljay@gmail.com" target="_blank">
                            Contact
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            {/* THE PLAN */}
            {/* THE VENUE */}
            {/* THE GUESTLIST */}
            {/* ABOUT/CONTACT */}
        </Holder>
    );
}

export default NavigationBar;