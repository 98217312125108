import React from 'react'
import Layout from '../UI/Layout'
import styled from '@emotion/styled'
import Flex from '../components/Flex'

const ThePlans = () => {

    const Background = styled.section`
    background: url(/images/darkness.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    @media (min-width: 700px) {
        height: 100vh;
      }
  `

    const Heading = styled.div`
        font-family: Bold;
        color: #fff;
        font-size: 3.5rem;
        font-weight: 900;
        letter-spacing: 1px;
        text-decoration: underline;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 40px;
    `

    const Copy = styled.p`
    font-family: Goth;
    text-align: center;
    color: #fff;
    font-size: 1.1rem;
    `

    return ( 
        <Layout>
            <Background>
            <Heading>details about the night</Heading>
            <Copy>The Groucho Club is a world-renowned arts & media private members club, based in the heart of Soho, London.</Copy>
            <Copy>Sarah’s party will be held in the exclusive Mary-Lou Room - an elegant and stylish space, complete with a private bar and a roaring fire.</Copy>
            <Copy>The party will begin at 7.30pm and go on until 2.30am. Dress to impress</Copy>
            <Copy style={{marginBottom: '45px'}}>Please find the location of the venue on the map below</Copy>
            <Flex justifyCenter >
            <iframe style={{boxShadow: '1px 2px 3px rgba(0,0,0,.5)'}} title='map' className='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.001799617353!2d-0.13413868422981476!3d51.513182979636085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604d2e2c789e9%3A0x9807fbf7458aedf!2sThe%20Groucho%20Club!5e0!3m2!1sen!2suk!4v1581335472446!5m2!1sen!2suk" width="700" height="400" frameborder="0" allowfullscreen=""></iframe>
            </Flex>
            </Background>
        </Layout>
     );
}
 
export default ThePlans;