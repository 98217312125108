import React from 'react'
import PhotoGallery from '../modules/PhotoGrid'
import Layout from '../UI/Layout';

const Gallery = () => {
    return ( 
        <Layout>
            <PhotoGallery/>
        </Layout>
     );
}
 
export default Gallery;