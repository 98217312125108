import React, { useState } from 'react'
import styled from '@emotion/styled'
import NavBar from '../components/NavBar'
import Wizard from '../modules/RSVP'

const Wrapper = styled.section`
    width: 100%;
    background-image: url(/images/newBackground.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 100vw 70vw;
`
const Layout = ({ children }) => {
   
    const [show, setShow] = useState(false);
   
    return (
        <>
            <NavBar show={show} setShow={setShow}  />
            {show && <Wizard show={show} setShow={setShow}/>}
            {/* <Wrapper> */}
                {children}
            {/* </Wrapper> */}
        </>
    );
}

export default Layout;