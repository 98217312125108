import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import axios from '../axiosOrder'
import Layout from '../UI/Layout'
import Flex from '../components/Flex'

const TheGuestlist = () => {

    const styles = {
    textAlign: 'center',
    width: '100%',
    textTransform: 'uppercase',
    listStyle: 'none',
    fontFamily: 'rom',
    marginBottom: '5px',
    letterSpacing: '0.5px',
    fontWeight: '200',
    }

      const [name, setName] = useState([])
      const [loading, setLoading] = useState(true)

      const Background = styled.section`
        background: url(/images/guestlistBackground.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
      `

      const fetchData = () => {
        axios.get('/Guestlist.json')
        .then(res => {
            const fetchedOrders = []
            for (let key in res.data){
                fetchedOrders.push({
                    ...res.data[key], 
                    id: key
                    })
            }
            setName(fetchedOrders)
            setLoading(false)
        })
        .catch(err => {
            console.log(err)
        })
      }

      useEffect(() => 
          fetchData()
       , [])

console.log(name, 'name')

    return ( 
        <Layout>
            <Background>
        <Flex justifyCenter>
        <div className='card card-4'>
             <h3 style={{fontFamily: 'goth', textAlign: 'center', marginBottom: '30px'}}>THE CROWD...so far</h3>
             <h6 style={{fontFamily: 'rom', textAlign: 'center', marginBottom: '30px'}}>Once RSVP'd your name will appear here...</h6>
             <ul style={{color: 'black', padding: 'unset'}}>
             {loading && <div className="loader">Loading...</div>}
        {name.map((i, index) => <li style={styles} key={index}>{i.name} {i.surname}</li>)}
             </ul>
        </div>
             {console.log(name, 'name2')}
            </Flex>
            </Background>
        </Layout>
     );
}
 
export default TheGuestlist;