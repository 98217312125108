import React from 'react';
import { useRoutes } from 'hookrouter';
// import Firebase from "firebase";
// import config from "./config";
// import 'firebase/auth';
// import 'firebase/firestore';
import { routes } from './routes'
import './carousel.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

// Firebase.initializeApp(config);

function App() {

  const routeResult = useRoutes(routes);

  return routeResult || <div>No Page Dound</div>;

}
export default App;
